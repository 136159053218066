




































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { IDrivingLessonsPerStudentReport } from "@/interfaces/IDrivingLessonsPerStudentReport";
import { PropType } from "vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Chart from "@/components/Charts/Chart.vue";
import sortBy from "lodash/sortBy";
import { ChartData } from "chart.js/auto";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";

@Component({
  components: { Chart, FscSimpleCard, Hint },
})
export default class ReportEducationChart extends Vue {
  public name = "ReportEducationChart";

  @Prop({ type: Object as PropType<IDrivingLessonsPerStudentReport> })
  public data!: IDrivingLessonsPerStudentReport;

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop()
  public filterInfo!: any;

  @Prop()
  public selectedRow!: any;

  public get map() {
    if (!this.data) return [];
    const arr: any = [];
    for (const [key, value] of Object.entries(this.data)) {
      arr.push({
        x: Number(key),
        y: value,
      });
    }
    return sortBy(arr, "x");
  }

  public get chartData(): ChartData {
    return {
      datasets: [
        {
          fill: true,
          showLine: true,
          tension: 0.3,
          label: "",
          data: this.map,
          backgroundColor: "rgba(22, 159, 219, 0.3)",
          borderColor: "#169FDB",
          pointBackgroundColor: "#169FDB",
          borderWidth: 2,
        },
      ],
    };
  }

  public chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
        beginAtZero: true,
      },
      x: {
        type: "linear",
        position: "bottom",
        ticks: {
          stepSize: 1,
        },
        //beginAtZero: true,
      },
    },
  };
}
