



































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { IDrivingLessonsPerStudentReport } from "@/interfaces/IDrivingLessonsPerStudentReport";
import { PropType } from "vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Chart from "@/components/Charts/Chart.vue";
import sortBy from "lodash/sortBy";
import { ChartData } from "chart.js/auto";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";

@Component({
  components: { Chart, FscSimpleCard, Hint },
})
export default class ReportEducationFillChart extends Vue {
  public name = "ReportEducationFillChart";

  @Prop()
  public data!: any;

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop()
  public filterInfo!: any;

  @Prop()
  public selectedRow!: any;

  public get daysBetweenStartAndPracticalExam() {
    if (!this.data) return [];
    const arr: any = [];
    for (const [key, value] of Object.entries(this.data[0])) {
      arr.push({
        x: Number(key),
        y: value,
      });
    }
    return arr;
  }

  public get daysBetweenFirstDrivingLessonAndPracticalExam() {
    if (!this.data) return [];
    const arr: any = [];
    for (const [key, value] of Object.entries(this.data[1])) {
      arr.push({
        x: Number(key),
        y: value,
      });
    }
    return arr;
  }

  public get daysBetweenFirstTheoryLessonAndTheoryExam() {
    if (!this.data) return [];
    const arr: any = [];
    for (const [key, value] of Object.entries(this.data[2])) {
      arr.push({
        x: Number(key),
        y: value,
      });
    }
    return arr;
  }

  public get chartData(): ChartData {
    return {
      datasets: [
        {
          fill: true,
          showLine: true,
          tension: 0.3,
          label: "Aktuel",
          data: this.daysBetweenStartAndPracticalExam,
          backgroundColor: "rgba(22, 159, 219, 0.3)",
          borderColor: "#169FDB",
          pointBackgroundColor: "#169FDB",
          borderWidth: 2,
        },
        {
          fill: true,
          showLine: true,
          tension: 0.3,
          label: "Vorjahr",
          data: this.daysBetweenFirstDrivingLessonAndPracticalExam,
          backgroundColor: "rgba(220, 12, 35, 0.3)",
          borderColor: "#dc0c23",
          pointBackgroundColor: "#dc0c23",
          borderWidth: 2,
        },
        {
          fill: true,
          showLine: true,
          tension: 0.3,
          label: "Markt",
          data: this.daysBetweenFirstTheoryLessonAndTheoryExam,
          backgroundColor: "rgba(233, 172, 6, 0.3)",
          borderColor: "#E9AC06FF",
          pointBackgroundColor: "#E9AC06FF",
          borderWidth: 2,
        },
      ],
    };
  }

  public chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },

    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
        beginAtZero: true,
      },
      x: {
        type: "linear",
        position: "bottom",
        ticks: {
          stepSize: 1,
        },
        beginAtZero: true,
      },
    },
  };
}
