


















































import { Vue, Component, Prop } from "vue-property-decorator";
import { PropType } from "vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import HintMessage from "@/views/AutomaticPaymentsOverview/HintMessage.vue";
import ReportSlotsCard from "@/views/Report/ReportSlotsCard.vue";
import { IReportTrainingDurationInDays } from "@/interfaces/IReportTrainingDurationInDays";
import ReportCardItem from "@/views/Report/HelperComponents/ReportCardItem.vue";
import ReportHeader from "@/views/Report/HelperComponents/ReportHeader.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";

@Component({
  components: { ReportSlotsCard, Hint, HintMessage, ReportCardItem, ReportHeader, PrintButton, DocumentButton },
})
export default class ReportTrainingDurationInDays extends Vue {
  public name = "ReportTrainingDurationInDays";

  @Prop({ required: false, type: Object as PropType<IReportTrainingDurationInDays> })
  public data!: IReportTrainingDurationInDays | any;

  @Prop({ type: Boolean, default: false })
  public loading!: boolean;
  public visible = false;

  public get infoActual() {
    return this.data && this.data.actual ? this.data.actual : this.data ? this.data : null;
  }

  public get infoMarked() {
    return this.data && this.data.marked ? this.data.marked : null;
  }

  public get infoPrevious() {
    return this.data && this.data.previous ? this.data.previous : null;
  }
  public showBlocks(e: boolean) {
    this.visible = e;
  }
}
