






























































































import { Component } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import ReportEducations from "@/views/Report/ReportEducations.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import ReportEducationFilter from "@/views/Report/ReportEducationFilter.vue";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IBranch } from "@/interfaces/IBranch";
import { IInstructor } from "@/interfaces/IInstructor";
import { IDrivingLessonsPerStudentReport } from "@/interfaces/IDrivingLessonsPerStudentReport";
import ReportEducationChart from "@/views/Report/ReportEducationChart.vue";
import ReportQuestionAnsweredToday from "@/views/Report/ReportQuestionAnsweredToday.vue";
import { IAnsweredQuestionsPerDrivingSchoolReport } from "@/interfaces/IAnsweredQuestionsPerDrivingSchoolReport";
import ReportRegistrationChart from "@/views/Report/ReportRegistrationChart.vue";
import { IRegistrationsPerDrivingSchoolReport } from "@/interfaces/IRegistrationsPerDrivingSchoolReport";
import ReportTrainingFilter from "@/views/Report/ReportTrainingFilter.vue";
import ReportTrainingDurationInDays from "@/views/Report/ReportTrainingDurationInDays.vue";
import { IReportTrainingDurationInDays } from "@/interfaces/IReportTrainingDurationInDays";
import eventBus from "@/plugins/EventBus";
import ReportLearnChart from "@/views/Report/ReportLearnChart.vue";
import DefaultInstructorSorting from "@/constants/DefaultInstructorSorting";
import ReportLearnPieChart from "@/views/Report/ReportLearnPieChart.vue";
import ReportLearnVerticalChart from "@/views/Report/ReportLearnVerticalChart.vue";
import ReportEducationFillChart from "@/views/Report/ReportEducationFillChart.vue";
import ReportRequestMixin from "@/mixins/Request/ReportRequestMixin";
import { mixins } from "vue-class-component";
import { IReportItem } from "@/interfaces/IReportItem";

const LicenseClassModule = namespace("license-class");
const BranchModule = namespace("branch");
const InstructorModule = namespace("instructor");
const ReportModule = namespace("report");
const PaymentWorkFlowModule = namespace("payment-workflow");
const LearnModule = namespace("learn");

@Component({
  components: {
    ReportTrainingDurationInDays,
    ReportTrainingFilter,
    ReportRegistrationChart,
    ReportQuestionAnsweredToday,
    ReportEducationChart,
    ReportEducationFilter,
    ReportEducations,
    FscSimpleCard,
    FscPageHeader,
    ReportLearnChart,
    ReportLearnPieChart,
    ReportLearnVerticalChart,
    ReportEducationFillChart,
  },
})
export default class Training extends mixins(ReportRequestMixin) {
  public name = "Training";

  @LicenseClassModule.Action("groupsList/findAll")
  public findAllLicenseClassGroups!: (options: ICrudOptions) => Promise<void>;

  @LicenseClassModule.Getter("groupsList/getDataList")
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @LicenseClassModule.Action("findByDrivingSchoolId")
  public findLicenseClassesByDrivingSchoolId!: any;

  @LicenseClassModule.Getter("getKeysByLicenseClass")
  public licenseClasses!: any;

  @BranchModule.Action("findAll")
  public findAllBranches!: (options: ICrudOptions) => Promise<void>;

  @BranchModule.Getter("getDataList")
  public branches!: Array<IBranch>;

  @InstructorModule.Action("filter")
  public filterInstructors!: (options: ICrudOptions) => Promise<void>;

  @InstructorModule.Getter("getDataList")
  public instructors!: Array<IInstructor>;

  @ReportModule.Action("drivingLessonsPerStudentEducation/filter")
  public filterDrivingLessonsPerStudentEducation!: (options: ICrudOptions) => Promise<void>;

  @ReportModule.Getter("drivingLessonsPerStudentEducation/getData")
  public drivingLessonsPerStudentEducation!: IDrivingLessonsPerStudentReport;

  @ReportModule.Getter("drivingLessonsPerStudentEducation/getIsLoading")
  public drivingLessonsPerStudentEducationLoading!: boolean;

  @ReportModule.Action("answeredQuestionsPerDrivingSchool/filter")
  public filterAnsweredQuestionsPerDrivingSchool!: (options: ICrudOptions) => Promise<void>;

  @ReportModule.Getter("answeredQuestionsPerDrivingSchool/getData")
  public answeredQuestionsPerDrivingSchool!: IAnsweredQuestionsPerDrivingSchoolReport;

  @ReportModule.Getter("answeredQuestionsPerDrivingSchool/getIsLoading")
  public answeredQuestionsPerDrivingSchoolLoading!: boolean;

  @ReportModule.Action("registrationsPerDrivingSchool/filter")
  public filterRegistrationsPerDrivingSchool!: (options: ICrudOptions) => Promise<void>;

  @ReportModule.Getter("registrationsPerDrivingSchool/getData")
  public registrationsPerDrivingSchool!: IRegistrationsPerDrivingSchoolReport;

  @ReportModule.Getter("registrationsPerDrivingSchool/getIsLoading")
  public registrationsPerDrivingSchoolLoading!: boolean;

  @ReportModule.Action("reportTrainingDurationInDays/filter")
  public filterReportTrainingDurationInDays!: (options: ICrudOptions) => Promise<void>;

  @ReportModule.Getter("reportTrainingDurationInDays/getData")
  public reportTrainingInDaysData!: IReportTrainingDurationInDays;

  @ReportModule.Getter("reportTrainingDurationInDays/getIsLoading")
  public reportTrainingInDaysLoading!: boolean;

  @PaymentWorkFlowModule.Action("findAll")
  public findAllPaymentWorkflows: any;

  @PaymentWorkFlowModule.Getter("getDataList")
  public allPaymentWorkflows: any;

  @LearnModule.Action("practicalExamSuccessRate")
  public findPracticalExamSuccessRate: any;

  @LearnModule.Getter("getDataList")
  public practicalExams: any;

  @LearnModule.Action("theoryExamSuccessRate")
  public findTheoryExamSuccessRate: any;

  @LearnModule.Getter("getDataList")
  public theoryExams: any;

  @LearnModule.Action("practicalExamPassedRate")
  public findPracticalExamPassedRate: any;

  @LearnModule.Getter("getDataList")
  public practicalPassedExams: any;

  @LearnModule.Action("theoryExamPassedRate")
  public findTheoryExamPassedRate: any;

  @LearnModule.Getter("getDataList")
  public theoryPassedExams: any;

  @LearnModule.Action("kmPerDrivingLesson")
  public findKmPerDrivingLesson: any;

  @LearnModule.Getter("getDataList")
  public kmPerDrivingLesson: any;

  @LearnModule.Action("gaussianDurationOfTraining")
  public findGaussianDurationOfTraining: any;

  @LearnModule.Getter("getDataList")
  public durationOfTraining: any;

  public selectedRow: null | IBasicNamedDTO = null;

  public filterInfo: any = null;

  public practicalExamData: any = null;

  public theoryExamData: any = null;

  public averageKmPerDrivingSchool: any = null;

  public gaussianDurationOfTraining: any = null;

  public showMessage = false;

  public onSelectedRow(row: IBasicNamedDTO): void {
    const id = row?.id;
    this.showMessage = id == 11 || id == 5;
    this.selectedRow = row;
    this.practicalExamData = null;
    this.theoryExamData = null;
    this.averageKmPerDrivingSchool = null;
    this.gaussianDurationOfTraining = null;
    this.filterInfo = null;
  }

  private get hasPermission() {
    return this.$can(this.MODULE_PAYMENT_WRITE);
  }

  private get reportEducations(): Array<IReportItem> {
    return [
      {
        id: 1,
        name: this.$t("report.number_dist").toString(),
      },
      {
        id: 11,
        name: this.$t("report.distribution_leadtime").toString(),
        visible: this.hasPermission,
      },
      {
        id: 5,
        name: this.$t("report.average_leadtime").toString(),
        visible: this.hasPermission,
      },
      {
        id: 9,
        name: this.$t("report.passed_theory").toString(),
      },
      {
        id: 6,
        name: this.$t("report.passed_practice").toString(),
      },
      {
        id: 8,
        name: this.$t("report.passed_theory_rate").toString(),
      },
      {
        id: 7,
        name: this.$t("report.passed_practice_rate").toString(),
      },
      {
        id: 10,
        name: this.$t("report.average_km").toString(),
      },
    ];
  }

  public info: any = {};
  public mounted(): void {
    this.findAllLicenseClassGroups({
      resource: "/license-class-groups",
    });

    this.findAllBranches({
      resource: "/branches",
      params: { archived: false },
    });

    this.filterInstructors({ resource: "instructor", filter: { archived: false, ...DefaultInstructorSorting } });

    this.findAllPaymentWorkflows({
      resource: "payment-workflows",
    });
    this.findLicenseClassesByDrivingSchoolId(1);

    this.fetchDailyDataStartPeriod();
  }

  public extractValuesByPropFromObject(obj: any, propName: any, propName2?: any): string {
    if (!obj || !propName) return "";
    if (Array.isArray(obj)) {
      const value = obj.map((item: any) => item[propName] + " " + (item[propName2] || ""));
      if (value && value?.length > 0) {
        return value.join(", ");
      }
    } else if (Object.keys(obj) && Object.keys(obj).includes(propName)) {
      return obj[propName];
    }
    return "";
  }
  public extractStringFromFilters() {
    const { startDate, endDate, licenseClassGroupIds, instructorIds, branchIds } = this.filterInfo;
    const licenseClasses = this.extractValuesByPropFromObject(licenseClassGroupIds, "name");
    const instructors = this.extractValuesByPropFromObject(instructorIds, "firstName", "lastName");

    const branches = this.extractValuesByPropFromObject(branchIds, "location");
    this.info = { startDate, endDate, licenseClasses, instructors, branches };
    eventBus.$emit("selected-filter", this.info);
  }
  public async onSubmitFilterForm(
    data: {
      licenseClassGroupIds: Array<number> | null;
      branchIds: Array<number> | null;
      instructorIds: Array<number> | null;
      startDate: string;
      endDate: string;
      paymentWorkflow: null | IBasicNamedDTO;
      licenseClassIds: Array<number> | null;
      licenseClasses: Array<number> | null;
    },
    filterInfo: any
  ): Promise<void> {
    this.filterInfo = filterInfo;
    this.extractStringFromFilters();
    if (this.isNumberOfDrivingHoursToFrequency) {
      await this.filterDrivingLessonsPerStudentEducation({
        resource: "/reports/driving-lessons-per-student-education",
        filter: data,
      });
    } else if (this.isArithmeticMeanHourlyHourNumber) {
      await this.filterAnsweredQuestionsPerDrivingSchool({
        resource: "/reports/answered-questions-per-driving-school",
        filter: data,
      });
    } else if (this.isNumberOfQuestionsAnsweredPerDay) {
      await this.filterAnsweredQuestionsPerDrivingSchool({
        resource: "/reports/answered-questions-per-driving-school",
        filter: data,
      });
    } else if (this.isReportTrainingInDays) {
      await this.filterReportTrainingDurationInDays({
        resource: "reports/average-lead-time-per-driving-school",
        filter: data,
      });
    } else if (this.isPracticalExamSuccessRateNumber) {
      this.theoryExamData = null;
      this.averageKmPerDrivingSchool = null;
      await this.findPracticalExamSuccessRate(data);
      this.practicalExamData = this.practicalExams ? this.practicalExams : null;
    } else if (this.isTheoryExamSuccessRateNumber) {
      this.practicalExamData = null;
      this.averageKmPerDrivingSchool = null;
      await this.findTheoryExamSuccessRate(data);
      this.theoryExamData = this.theoryExams ? this.theoryExams : null;
    } else if (this.isPracticalExamSuccessRate) {
      this.theoryExamData = null;
      this.averageKmPerDrivingSchool = null;
      await this.findPracticalExamPassedRate(data);
      this.practicalExamData = this.practicalPassedExams ? this.practicalPassedExams : null;
    } else if (this.isTheoryExamSuccessRate) {
      this.practicalExamData = null;
      this.averageKmPerDrivingSchool = null;
      await this.findTheoryExamPassedRate(data);
      this.theoryExamData = this.theoryPassedExams ? this.theoryPassedExams : null;
    } else if (this.isKmInDrivingLesson) {
      this.practicalExamData = null;
      this.theoryExamData = null;
      await this.findKmPerDrivingLesson(data);
      this.averageKmPerDrivingSchool = this.kmPerDrivingLesson ? this.kmPerDrivingLesson : null;
    } else if (this.isGaussianDurationOfTraining) {
      this.practicalExamData = null;
      this.theoryExamData = null;
      this.averageKmPerDrivingSchool = null;
      await this.findGaussianDurationOfTraining(data);
      this.gaussianDurationOfTraining = this.durationOfTraining ? this.durationOfTraining : null;
    }
  }

  public get isNumberOfDrivingHoursToFrequency(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 1);
  }

  public get isArithmeticMeanHourlyHourNumber(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 2);
  }

  public get isReportRegistration(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 3);
  }

  public get isNumberOfQuestionsAnsweredPerDay(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 4);
  }

  public get isReportTrainingInDays(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 5);
  }

  public get isPracticalExamSuccessRateNumber(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 6);
  }

  public get isPracticalExamSuccessRate(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 7);
  }

  public get isTheoryExamSuccessRate(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 8);
  }

  public get isTheoryExamSuccessRateNumber(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 9);
  }

  public get isKmInDrivingLesson(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 10);
  }

  public get isGaussianDurationOfTraining(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 11);
  }
}
